import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    container,
    ribbonRight,
    prizeDesktop,
    prizeMobile,
    ribbonLeft,
    info,
} from './valentines-prize.module.scss';

import Markdown from '../hoc/markdown';

interface IValentinesPrizeProps {
    className?: string;
}

const prizeDesktopSrc = '../../assets/images/valentines/prize-desktop.png';
const prizeMobileSrc = '../../assets/images/valentines/prize-mobile.png';
const ribbonLeftSrc = '../../assets/images/valentines/ribbon-left.png';
const ribbonRightSrc = '../../assets/images/valentines/ribbon-right.png';

const ValentinesPrize: React.FC<IValentinesPrizeProps> = ({ className = '' }) => {
    const { t } = useI18next();

    return (
        <div className={`${container} ${className}`}>
            <div className={ribbonRight}>
                <StaticImage src={ribbonRightSrc} alt={''} />
            </div>
            <div className={prizeDesktop}>
                <StaticImage src={prizeDesktopSrc} alt={''} />
            </div>
            <div className={prizeMobile}>
                <StaticImage src={prizeMobileSrc} alt={''} />
            </div>
            <div className={ribbonLeft}>
                <StaticImage src={ribbonLeftSrc} alt={''} />
            </div>
            <div className={info}>
                <Markdown>{t('valentines.prize.info.1')}</Markdown>
                <p>{t('valentines.prize.info.2')}</p>
                <p>{t('valentines.prize.info.3')}</p>
            </div>
        </div>
    );
};

export default ValentinesPrize;
