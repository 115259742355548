import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { prize, subtitle, header, layout } from './thank-you.module.scss';
import { IPage } from '../../../models/page.model';

import ValentinesLayout from '../../../layouts/valentines-layout';
import ValentinesPrize from '../../../components/organisms/valentines-prize';

interface IHalloweenThankYouPageProps {
    readonly data: {
        page: IPage | null;
    };
}
const ThankYou: React.FC<IHalloweenThankYouPageProps> = ({ data }) => {
    const { t } = useI18next();
    const { page } = data;
    const from =
        typeof window !== 'undefined'
            ? new URLSearchParams(window.location.search).get('from')
            : '';

    return (
        <ValentinesLayout
            page={page}
            layoutClassName={layout}
            headerProps={
                from === 'winner'
                    ? {
                          titleSecondLine: t('valentines.thank.title.winner'),
                          subtitle: t('valentines.thank.subtitle.winner'),
                          subtitleClassName: subtitle,
                          className: header,
                      }
                    : {
                          titleFirstLine: t('valentines.thank.title.1'),
                          titleSecondLine: t('valentines.thank.title.2'),
                      }
            }
            pageType={from === 'winner' ? 'thank-you-winner' : 'thank-you'}
        >
            {from !== 'winner' && <ValentinesPrize className={prize} />}
        </ValentinesLayout>
    );
};

export const query = graphql`
    query ($language: String!, $site: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        page: strapiPage(
            locale: { eq: $language }
            site: { eq: $site }
            isDummyContent: { eq: false }
        ) {
            ...pageFields
        }
    }
`;

export default ThankYou;
