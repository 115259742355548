import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';

import {
    layout,
    hideBanner,
    container,
    content,
    header,
    hasHeader,
    thanks,
    headerContent,
    mainLayout,
    contact,
    banner,
    bottleRaspberry,
    heartPetals,
    imagesMain,
    imagesWinner,
    bottleHeartTop,
    handsStar,
    lips,
    imagesExpired,
    bottleHeartLeft,
    heartStar,
    imagesThankYouWinner,
    bottleLetter,
    hands,
} from './valentines-layout.module.scss';
import { grid } from '../styles/grid.module.scss';

import staticFiles from '../config/static-files';

import MainLayout, { IMainLayoutProps } from './main-layout';
import ContestThankYou, { IContestThankYouProps } from '../components/organisms/contest-thank-you';
import ContestContact from '../components/organisms/contest-contact';

interface IValentinesLayoutProps extends IMainLayoutProps {
    pageType: 'main' | 'thank-you' | 'winner' | 'expired' | 'closed' | 'thank-you-winner';
    children?: React.ReactNode;
    headerProps?: IContestThankYouProps;
    showSideImages?: boolean;
    layoutClassName?: string;
}

const bottleRaspberrySrc = '../assets/images/valentines/bottle-raspberry.png';
const heartPetalsSrc = '../assets/images/valentines/heart-petals.png';
const bottleHeartTopSrc = '../assets/images/valentines/bottle-heart-top.png';
const handsStarSrc = '../assets/images/valentines/hands-star.png';
const lipsSrc = '../assets/images/valentines/lips.png';
const bottleHeartLeftSrc = '../assets/images/valentines/bottle-heart-left.png';
const heartStarSrc = '../assets/images/valentines/heart-star.png';
const handsSrc = '../assets/images/valentines/hands.png';
const bottleLetterSrc = '../assets/images/valentines/bottle-letter.png';

const ValentinesLayout: React.FC<IValentinesLayoutProps> = ({
    children,
    pageType,
    className = '',
    layoutClassName = '',
    headerProps,
    showSideImages = false,
    ...rest
}) => {
    return (
        <MainLayout
            {...rest}
            className={`${layout} ${headerProps ? hasHeader : ''} ${layoutClassName}`}
            bannerClass={`${banner} ${rest.bannerClass}`}
            containerClassName={`${mainLayout} ${pageType === 'closed' ? hideBanner : ''}`}
        >
            <div className={`${headerProps ? grid : ''} ${header}`}>
                <div className={headerContent}>
                    {headerProps && (
                        <ContestThankYou
                            {...headerProps}
                            className={`${thanks} ${headerProps.className || ''}`}
                        />
                    )}
                </div>
            </div>
            <div className={`${container} ${grid}`}>
                {pageType === 'winner' && (
                    <div className={imagesWinner}>
                        <div className={lips}>
                            <StaticImage src={lipsSrc} alt={''} />
                        </div>
                    </div>
                )}
                <div className={`${className} ${content}`}>{children}</div>
                <ContestContact
                    className={contact}
                    regulationsUrl={staticFiles.valentinesRegulations}
                />
                {pageType === 'main' && (
                    <div className={imagesMain}>
                        <div className={bottleRaspberry}>
                            <StaticImage src={bottleRaspberrySrc} alt={''} />
                        </div>
                        <div className={heartPetals}>
                            <StaticImage src={heartPetalsSrc} alt={''} />
                        </div>
                    </div>
                )}
                {pageType === 'winner' && (
                    <div className={imagesWinner}>
                        <div className={bottleHeartTop}>
                            <StaticImage src={bottleHeartTopSrc} alt={''} />
                        </div>
                        <div className={handsStar}>
                            <StaticImage src={handsStarSrc} alt={''} />
                        </div>
                    </div>
                )}
                {pageType === 'expired' && (
                    <div className={imagesExpired}>
                        <div className={bottleHeartLeft}>
                            <StaticImage src={bottleHeartLeftSrc} alt={''} />
                        </div>
                        <div className={heartStar}>
                            <StaticImage src={heartStarSrc} alt={''} />
                        </div>
                    </div>
                )}
                {pageType === 'thank-you-winner' && (
                    <div className={imagesThankYouWinner}>
                        <div className={bottleLetter}>
                            <StaticImage src={bottleLetterSrc} alt={''} />
                        </div>
                        <div className={hands}>
                            <StaticImage src={handsSrc} alt={''} />
                        </div>
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export default ValentinesLayout;
